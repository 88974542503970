import request, { ResponsePageableData } from '@/libs/request'

/** 酒店设施列表 */
export const getHotelFacilityList = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelFacility/getHotelFacilityList', data })

/** 酒店设施新增/编辑 */
export const editHotelFacility = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelFacility/editHotelFacility', data })

/** 酒店设施删除 */
export const delHotelFacility = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelFacility/delHotelFacility', data })
