import request, { ResponsePageableData } from '@/libs/request'

/** 集团公司列表 */
export const getGroupCompanyList = (data?) => request<ResponsePageableData>({ url: '/adminapi/GroupCompany/getGroupCompanyList', data })

/** 集团公司新增/编辑 */
export const editGroupCompany = (data?) => request<ResponsePageableData>({ url: '/adminapi/GroupCompany/editGroupCompany', data })

/** 集团公司删除 */
export const delGroupCompany = (data?) => request<ResponsePageableData>({ url: '/adminapi/GroupCompany/delGroupCompany', data })
