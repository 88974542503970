





import Vue from 'vue'
import SelectMixin from './select-mixin'
import { getRoomRequireReadList } from '@/api/house-require-read'
/**
 * 订房必读选择器
 *
 * @event input(val) 数据变化，包括切换、选择、清空都会触发，启用多选模式后会是一个数组
 * @event load(rows,total) 加载数据完成，rows数据列，total总条数
 */

export default Vue.extend({
	mixins: [SelectMixin],
	props: {
		placeholder: {
			type: String,
			default: '请选择订房必读',
		},
	},
	data() {
		return {
			api: getRoomRequireReadList,
		}
	},
})
