import request, { ResponsePageableData } from '@/libs/request'

/** 酒店评价标签列表 */
export const getHotelEvaluationLabelList = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelEvaluationLabel/getHotelEvaluationLabelList', data })

/** 酒店评价标签新增/编辑 */
export const editHotelEvaluationLabel = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelEvaluationLabel/editHotelEvaluationLabel', data })

/** 酒店评价标签删除 */
export const delHotelEvaluationLabel = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelEvaluationLabel/delHotelEvaluationLabel', data })
