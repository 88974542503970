





































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getHotelScoreDimensionList, editHotelScoreDimension, delHotelScoreDimension } from '@/api/hotel-score-dimension'
export default Vue.extend({
	data() {
		return {
			editHotelScoreDimension,
			...useTable({
				name: '酒店评分维度',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
					//图标
				},
				getData: (query) => getHotelScoreDimensionList(query).then((res) => res.data),
				delData: (row) => delHotelScoreDimension({ id: row.id }),
			}),
		}
	},
	created() {
		this.tableGetData()
	},
})
