import request, { ResponsePageableData } from '@/libs/request'

/** 酒店特色列表 */
export const getHotelFeatureList = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelFeature/getHotelFeatureList', data })

/** 酒店特色新增/编辑 */
export const editHotelFeature = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelFeature/editHotelFeature', data })

/** 酒店特色删除 */
export const delHotelFeature = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelFeature/delHotelFeature', data })
