import request, { ResponsePageableData } from '@/libs/request'

/** 酒店政策列表 */
export const getHotelPolicyList = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelPolicy/getHotelPolicyList', data })

/** 酒店政策新增/编辑 */
export const editHotelPolicy = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelPolicy/editHotelPolicy', data })

/** 酒店政策删除 */
export const delHotelPolicy = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelPolicy/delHotelPolicy', data })
